import { tns } from 'tiny-slider/src/tiny-slider'

/**
 * Testimonials Block.
 * @see https://github.com/ganlanyuan/tiny-slider
 */
if (document.querySelector('.block--testimonials .testimonials')) {
  document.querySelectorAll('.block--testimonials .testimonials').forEach(block => {
    tns({
      container: block,
      mode: 'gallery',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 5000,
      controls: true,
      controlsText: [
        '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20.9 33.6"><path d="M20.9,4.2l-12.6,12.6,12.6,12.6-4.2,4.2L0,17l.2-.2-.2-.2L16.6,0l4.2,4.2Z" style="fill: #ffffff; stroke-width: 0px;"/></svg>',
        '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20.9 33.6"><path d="M0,29.3l12.6-12.6L0,4.2,4.2,0l16.6,16.6-.2.2.2.2L4.2,33.6,0,29.3Z" style="fill: #ffffff; stroke-width: 0px;"/></svg>'
      ],
      nav: false,
      responsive: {
        768: {
          controlsText: [
            '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.3 72.1"><polygon points="0 36.1 4.2 31.8 4.2 31.8 36.1 0 40.3 4.2 8.5 36.1 40.3 67.9 36.1 72.1 0 36.1" style="fill: #ffffff; stroke-width: 0px;"/></svg>',
            '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.3 72.1"><polygon points="4.2 72.1 0 67.9 31.8 36.1 0 4.2 4.2 0 36.1 31.8 36.1 31.8 40.3 36.1 4.2 72.1" style="fill: #ffffff; stroke-width: 0px;"/></svg>'
          ],
        },
      },
    })
  })
}

/**
 * Photo Gallery Block.
 * @see https://github.com/ganlanyuan/tiny-slider
 */
if (document.querySelector('.block--photo-gallery')) {

  /**
   * Setup a photo gallery block.
   */
  function setupPhotoGallery(block) {
  
    // Main
    const slider = tns({
      container: block.querySelector('.photo-gallery__main .main__photos'),
      mode: 'gallery',
      controls: false,
      lazyload: true,
      controls: true,
      controlsText: [
        '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20.9 33.6"><path d="M20.9,4.2l-12.6,12.6,12.6,12.6-4.2,4.2L0,17l.2-.2-.2-.2L16.6,0l4.2,4.2Z" style="fill: #000000; stroke-width: 0px;"/></svg>',
        '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20.9 33.6"><path d="M0,29.3l12.6-12.6L0,4.2,4.2,0l16.6,16.6-.2.2.2.2L4.2,33.6,0,29.3Z" style="fill: #000000; stroke-width: 0px;"/></svg>'
      ],
      nav: false,
      responsive: {
        768: {
          controlsText: [
            '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.3 72.1"><polygon points="0 36.1 4.2 31.8 4.2 31.8 36.1 0 40.3 4.2 8.5 36.1 40.3 67.9 36.1 72.1 0 36.1" style="fill: #000000; stroke-width: 0px;"/></svg>',
            '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.3 72.1"><polygon points="4.2 72.1 0 67.9 31.8 36.1 0 4.2 4.2 0 36.1 31.8 36.1 31.8 40.3 36.1 4.2 72.1" style="fill: #000000; stroke-width: 0px;"/></svg>'
          ],
        },
      },
    })

    // Thumbnails
    const thumbs = tns({
      container: block.querySelector('.photo-gallery__thumbs .thumbs__thumbs'),
      mode: 'carousel',
      items: 5,
      slideBy: 1,
      gutter: 6,
      fixedWidth: 180,
      controls: true,
      controlsText: [
        '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20.9 33.6"><path d="M20.9,4.2l-12.6,12.6,12.6,12.6-4.2,4.2L0,17l.2-.2-.2-.2L16.6,0l4.2,4.2Z" style="fill: #000000; stroke-width: 0px;"/></svg>',
        '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20.9 33.6"><path d="M0,29.3l12.6-12.6L0,4.2,4.2,0l16.6,16.6-.2.2.2.2L4.2,33.6,0,29.3Z" style="fill: #000000; stroke-width: 0px;"/></svg>'
      ],
      nav: false,
      responsive: {
        768: {
          controlsText: [
            '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.3 72.1"><polygon points="0 36.1 4.2 31.8 4.2 31.8 36.1 0 40.3 4.2 8.5 36.1 40.3 67.9 36.1 72.1 0 36.1" style="fill: #000000; stroke-width: 0px;"/></svg>',
            '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.3 72.1"><polygon points="4.2 72.1 0 67.9 31.8 36.1 0 4.2 4.2 0 36.1 31.8 36.1 31.8 40.3 36.1 4.2 72.1" style="fill: #000000; stroke-width: 0px;"/></svg>'
          ],
        },
      },
    })

    // Thumbnail click
    block.querySelectorAll('.photo-gallery__thumbs .thumbs__thumbs .thumbs__thumb .thumb__button').forEach(thumb => {
      thumb.addEventListener('click', (e) => {
        const index = thumb.dataset.index
        slider.goTo(index)
      })
    })
  }

  /**
   * Loop through and setup all photo gallery blocks.
   */
  document.querySelectorAll('.block--photo-gallery').forEach(block => {
    setupPhotoGallery(block)
  })
}
